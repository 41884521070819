<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_hospitalization") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <template
                v-if="
                  (bunk.id ? bunk.id : '') &&
                  (bunk.id ? bunk.id : '') !=
                    (form.patientSchedule ? form.patientSchedule.id : '')
                "
              >
                <el-button disabled>{{ $t("message.close") }}</el-button>
              </template>
              <template v-else>
                <el-button @click="closePage()">{{
                  $t("message.close")
                }}</el-button>
              </template>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form" v-loading="loadingData">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="aticler_m"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :sm="12">
            <el-form-item :label="$t('message.number_card')">
              <el-input v-model="form.number" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item
              :label="$t('message.date_hospitalization')"
              prop="date_gos"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_gos"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item
              :label="$t('message.diagnos')"
              prop="diagnos"
              class="ik_label"
            >
              <el-input v-model="form.diagnos" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.doctor')" prop="doctor_id">
              <users
                v-model="form.doctor_id"
                :doctor_id="form.doctor_id"
              ></users>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.branch')" prop="branch_id">
              <branches
                v-model="form.branch_id"
                :branch_id="form.branch_id"
              ></branches>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :sm="12">
            <el-form-item :label="$t('message.hospitalization_type')">
              <typeHospitalizations
                v-model="form.type_hospitalization_id"
                :type_hospitalization_id="form.type_hospitalization_id"
              ></typeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item :label="$t('message.purpose_of_hospitalization')">
              <purposeHospitalizations
                v-model="form.purpose_hospitalization_id"
                :purpose_hospitalization_id="form.purpose_hospitalization_id"
              ></purposeHospitalizations>
            </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item :label="$t('message.blood')">
              <el-select v-model="form.blood" placeholder=".....">
                <el-option label="O(I) Rh−" value="first_minus"></el-option>
                <el-option label="O(I) Rh+" value="first_plus"></el-option>
                <el-option label="A(II) Rh−" value="second_minus"></el-option>
                <el-option label="A(II) Rh+" value="second_plus"></el-option>
                <el-option label="B(III) Rh−" value="third_minus"></el-option>
                <el-option label="B(III) Rh−" value="third_plus"></el-option>
                <el-option label="AB(IV) Rh−" value="fourth_minus"></el-option>
                <el-option label="AB(IV) Rh+" value="fourth_plus"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.puls')" class="ik_label">
              <el-row>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_one"
                    placeholder="......"
                  ></el-input>
                </el-col>
                <el-col class="line text-center" :span="2">-</el-col>
                <el-col :span="11">
                  <el-input
                    v-model="form.impuls_two"
                    placeholder="......"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.temperature')">
              <el-input
                v-model="form.teperatura"
                placeholder="......"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.condition')">
              <injuryStatuses
                v-model="form.status_id"
                :status_id="form.status_id"
              ></injuryStatuses>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.weight')">
              <el-input v-model="form.weight" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.height')">
              <el-input v-model="form.height" placeholder="......"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.type_of_injury')">
              <injuryTypes
                v-model="form.injury_type_id"
                :injury_type_id="form.injury_type_id"
              ></injuryTypes>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.allergy')">
              <el-input type="textarea" v-model="form.allergy"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item :label="$t('message.date_injury')">
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_injury"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8">
            <el-form-item
              :label="$t('message.date_register')"
              prop="date_register"
            >
              <el-date-picker
                type="date"
                placeholder=".. / .. / ...."
                v-model="form.date_register"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-tabs type="border-card" class="mt-2">
              <el-tab-pane :label="$t('message.pockets')">
                <div style="width: 100%" class="user-kod d-flex flex-column">
                  <el-row class="mb-3" :gutter="20">
                    <el-col :sm="10">
                      <div class="title_cet m-2">
                        {{ $t("message.pockets") }}
                      </div>
                      <div class="user-kod-top">
                        <el-select
                            v-model="pocket_id"
                            filterable
                            clearable
                            size="mini"
                            :placeholder="$t('message.pockets')"
                        >
                          <el-option
                              v-for="item in pockets"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <el-button
                            v-if="pocket_id"
                            class="ml-4"
                            type="success"
                            @click="addPocket"
                        >{{
                            $t(`message.add`)
                          }}</el-button>
                      </div>
                    </el-col>
                  </el-row>
                  <el-collapse>
                    <el-collapse-item v-for="(pocket, index) in selected_pockets" :title="pocketTitle(index)">
                      <el-card class="p-4 shadow-md rounded-lg">
                        <el-row :gutter="20">
                          <el-col :span="8" v-for="(field, fieldKey) in pocketFields(pocket)" :key="fieldKey">
                            <div class="userPasten_info">
                              <div class="title_pp font-medium text-gray-600">{{ field.label }}:</div>
                              <div class="name_h text-gray-800">{{ field.value }}</div>
                            </div>
                          </el-col>
                          <el-col :span="8">
                            <el-button
                                class="mt-4"
                                type="danger"
                                icon="el-icon-delete"
                                circle
                                size="mini"
                                @click="removePocket(index)"
                            >
                              {{ $t(`message.remove`) }}
                            </el-button>
                          </el-col>
                          <el-col :span="8">
                            <el-button
                                class="mt-4"
                                type="primary"
                                icon="el-icon-edit"
                                circle
                                size="mini"
                                @click="openSchedule(index)"
                            >{{ $t("message.update") }}
                            </el-button>
                          </el-col>
                        </el-row>
                      </el-card>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('message.direction')">
                <el-row :gutter="25">
                  <el-col :sm="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <el-select
                          v-model="form.partner_clinic_id"
                          filterable
                          clearable
                          size="mini"
                          @change="filterDepartment"
                          :placeholder="$t('message.partner_clinic')"
                        >
                          <el-option
                            v-for="item in partnerClinics"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <!-- <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      > -->
                    </div>
                  </el-col>
                  <el-col :sm="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <el-select
                          size="mini"
                          v-model="form.department_id"
                          filterable
                          clearable
                          :placeholder="$t('message.department')"
                        >
                          <el-option
                            v-for="item in departments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerDepartment = true"
                        >{{ $t("message.add_new_department") }}</el-button
                      >
                    </div>
                  </el-col>
                  <el-col :sm="8">
                    <div class="user-kod">
                      <div class="title_cet">{{ $t("message.from_whom") }}</div>
                      <div class="user-kod-top">
                        <partnerDoctor
                          v-model="form.partner_doctor_id"
                          :partner_clinic_id="form.partner_clinic_id"
                          :id="model.partner_doctor_id"
                          :partner_doctor_id="form.partner_doctor_id"
                          size="mini"
                        >
                        </partnerDoctor>
                      </div>
                      <el-button
                        class="add_pats"
                        @click="drawerPartner = true"
                        >{{ $t("message.add_new_partner_doctor") }}</el-button
                      >
                    </div>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end el-row -->
              </el-tab-pane>
<!--              <el-tab-pane :label="$t('message.add_to_room')">
                <el-row :gutter="20">
                  <el-col :span="8" v-if="classs">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.class") }}:</div>
                      <div class="name_h" v-text="classs"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.class") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.classs.name
                            : classs
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="corps">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.corps") }}:</div>
                      <div class="name_h" v-text="corps">Корпус №1</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.corps") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.corps.name
                            : corps
                        "
                      >
                        Корпус №1
                      </div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="floor">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.floor") }}:</div>
                      <div class="name_h" v-text="floor"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.floor") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.floor.name
                            : floor
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="room">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.room") }}:</div>
                      <div class="name_h" v-text="room"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.room") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.hospitalRoom.name
                            : room
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="bunkname">
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.bunk") }}:</div>
                      <div class="name_h" v-text="bunkname"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">{{ $t("message.bunk") }}:</div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.bunk.name
                            : bunkname
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="start">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.arrival_date") }}:
                      </div>
                      <div class="name_h" v-text="start"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.arrival_date") }}:
                      </div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule
                            ? form.patientSchedule.start
                            : start
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                  <el-col :span="8" v-if="end">
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.departure_date") }}:
                      </div>
                      <div class="name_h" v-text="end"></div>
                    </div>
                  </el-col>
                  <el-col :span="8" v-else>
                    <div class="userPasten_info">
                      <div class="title_pp">
                        {{ $t("message.departure_date") }}:
                      </div>
                      <div
                        class="name_h"
                        v-text="
                          form.patientSchedule ? form.patientSchedule.end : end
                        "
                      ></div>
                    </div>
                  </el-col>
                  &lt;!&ndash; end el-col &ndash;&gt;
                </el-row>

                <el-col :span="24">
                  <el-button
                    icon="el-icon-edit"
                    @click="drawer.room.status = true"
                    type="primary"
                    round
                    class="mb-1 mb-2"
                    >{{ $t("message.update") }}</el-button
                  >
                </el-col>
              </el-tab-pane>-->
            </el-tabs>
          </el-col>
        </el-row>

        <!-- end el-row -->
      </el-form>
    </div>
    <el-drawer
      title="I am the title"
      :append-to-body="true"
      :visible.sync="drawer.room.status"
      size="100%"
      :wrapperClosable="false"
      :ref="drawer.room.name"
      :with-header="false"
      @opened="drawerOpened(drawer.room.component)"
      @closed="drawerClosed(drawer.room.component)"
    >
      <ModalUpdate
        :ref="drawer.room.component"
        :drawer-name="drawer.room.name"
      />
    </el-drawer>

    <el-drawer
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawerPartner"
      size="40%"
    >
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalUpdate from "./modalUpdate";
import form from "@/utils/mixins/form";
import branches from "@/components/selects/branchWarehouse";
import users from "@/components/selects/user_list";
import drawerChild from "@/utils/mixins/drawer-child";
import injuryTypes from "@/components/selects/injuryType";
import injuryStatuses from "@/components/selects/injuryStatus";
import typeHospitalizations from "@/components/selects/typeHospitalization";
import purposeHospitalizations from "@/components/selects/purposeHospitalization";

import partnerDoctor from "@/components/inventory-select/partner-doctor";
import CreateDepartment from "@/views/scheduler/add-department";
import AddPartnerDoctor from "@/views/scheduler/add-partner-doctor";
import drawer from "@/utils/mixins/drawer";
import {i18n} from "@/utils/i18n";
import store from '@/store';
import _ from "lodash";

export default {
  props: ["selected"],
  mixins: [form, drawerChild, drawer],
  components: {
    branches,
    CreateDepartment,
    injuryTypes,
    purposeHospitalizations,
    users,
    typeHospitalizations,
    injuryStatuses,
    ModalUpdate,
    partnerDoctor,
    AddPartnerDoctor,
  },
  data() {
    return {
      loadingData: false,
      form: {},
      corps: "",
      floor: "",
      classs: "",
      room: "",
      drawerDepartment: false,
      bunkname: "",
      start: "",
      pocket_id: null,
      selected_pockets: [],
      schedule_pocket_index: "",
      end: "",
      drawerPartner: false,
      drawer: {
        room: {
          status: false,
          name: "room",
          component: "roomComponent",
        },
      },
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      rules: "patientHistories/rules",
      model: "patientHistories/model",
      columns: "patientHistories/columns",
      selected_partner_doctor: "partnerDoctor/selected_partner_doctor",
      bunk: "schedulesCabinets/bunk",
      pockets: "pocketsName/list",
      departments: "department/list",
      partnerClinics: "partnerClinic/list",
      partnerDoctors: "partnerDoctor/list",
      lastHistory: "patientHistories/model",
    }),
  },

  methods: {
    ...mapActions({
      save: "patientHistories/update",
      setFIO: "patients/setPatientFIO",
      emptyFIO: "patients/emptyPatientFIO",
      updateList: "pocketsName/index",
      empty: "patientHistories/empty",
      emptyBunk: "schedulesCabinets/empty",
      editModel: "patientHistories/show",
      loadDepartments: "department/index",
      partnerClinicsList: "partnerClinic/index",
      partnerDoctorsList: "partnerDoctor/inventory",
    }),
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    lowPriceFilter(val, price) {
      return (
        parseFloat(Math.min(...val.map((item) => item.price))) +
        parseFloat(price)
      );
    },
    roomPriceFilter(val, id, price) {
      return val.find((el) => el.room_id == id)
        ? parseFloat(val.find((el) => el.room_id == id).price) +
            parseFloat(price)
        : "";
    },
    totalPriceFilter(val, id, days, price) {
      return val.find((el) => el.room_id == id)
        ? (parseFloat(val.find((el) => el.room_id == id).price) +
            parseFloat(price)) *
            days
        : "";
    },
    async afterOpened() {
      await this.getData();
      var order_query = { column: "name", order: "asc" };
      if (this.pockets && this.pockets.length === 0) this.updateList();

      if (this.partnerDoctors && this.partnerDoctors.length === 0)
        this.partnerDoctorsList(order_query);

      if (this.departments && this.departments.length === 0)
        this.loadDepartments(order_query);

      if (this.partnerClinics && this.partnerClinics.length === 0)
        this.partnerClinicsList(order_query);

      // this.filterPocket(form.pocket_id);
    },
    afterClosed() {
      this.empty();
    },
    // filterPocket(val) {
    //   this.pocket = this.pockets.find((element) => element.id == val);
    // },
    addPocket() {
      let pocket = this.pockets.find((pocket) => pocket.id == this.pocket_id);
      this.selected_pockets.push(pocket);
      this.pocket_id = null;
    },
    removePocket(index) {
      this.selected_pockets.splice(index, 1);
    },
    getData() {
      this.loadingData = true;
      var vm = this;
      this.editModel({
        patient_id: vm.getId,
        id: this.lastHistory.id,
      })
        .then((res) => {
          this.form = JSON.parse(JSON.stringify(this.model));
          this.form.hospital_patient_id = this.selected.hospitalPatient.id;
          this.loadingData = false;
          const patient = this.form.hospitalPatient;
          this.setFIO(patient.first_name + ' ' + patient.surname);
          this.loadPockets();
          this.form.pocket_id =
            this.form.patientHistoryPocket &&
            this.form.patientHistoryPocket.pocket
              ? this.form.patientHistoryPocket.pocket.id
              : null;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.form.old_pockets = [];
      this.form.new_pockets = [];
      this.selected_pockets.forEach(pocket => {
        if (pocket && !pocket.patient_schedule_id) {
          return this.$notify({
            title: "Внимание",
            type: "warning",
            offset: 130,
            message: "Выберите комнату, пожалуйста",
          });
        }
        pocket.pocketPrice = pocket.price;
        pocket.totalPrice = this.totalPriceFilter(
            pocket.pocket_rooms,
            pocket.room_id,
            pocket.numberOfDays,
            pocket.price
        );
        pocket.roomPrice = this.roomPriceFilter(
            pocket.pocket_rooms,
            pocket.room_id,
            pocket.price
        );
        pocket.lowPrice = this.lowPriceFilter(
            pocket.pocket_rooms,
            pocket.price
        );
        pocket.old ? this.form.old_pockets.push(pocket) : this.form.new_pockets.push(pocket);
      })
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.parent().listChanged();
              if (close == true) {
                this.selected_pockets = [];
                this.emptyFIO();
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    loadBunk() {
      if (_.isPlainObject(this.bunk)) {
        const updatedPocket = {
          ...this.selected_pockets[this.schedule_pocket_index],
          classs: this.bunk.bunk ? this.bunk.bunk.classs.name : "",
          corps: this.bunk.bunk ? this.bunk.bunk.corps.name : "",
          floor: this.bunk.bunk ? this.bunk.bunk.floor.name : "",
          room: this.bunk.bunk ? this.bunk.bunk.hospitalRoom.name : "",
          bunkname: this.bunk.bunk ? this.bunk.bunk.name : "",
          start: this.bunk.start,
          end: this.bunk.end,
          patient_schedule_id: this.bunk.id,
        };
        this.$set(this.selected_pockets, this.schedule_pocket_index, updatedPocket);
        this.emptyBunk();
        store.dispatch('schedulesCabinets/clearScheduleId');
      }
    },
    loadPockets() {
      this.form.patientHistoryPocket.forEach((pocket) => {
        const old_pocket = {
          old: true,
          branch_id: pocket.pocket ? pocket.pocket.branch_id : "",
          description: pocket.pocket ? pocket.pocket.description : "",
          id: pocket.pocket ? pocket.pocket.id : "",
          name: pocket.pocket ? pocket.pocket.name : "",
          pocket_laboratory_services: pocket.pocket ? pocket.pocket.pocket_laboratory_services : "",
          pocket_physiotherapies: pocket.pocket ? pocket.pocket.pocket_physiotherapies : "",
          pocket_rooms: pocket.pocket ? pocket.pocket.pocket_rooms : "",
          pocket_services: pocket.pocket ? pocket.pocket.pocket_services : "",
          pocket_treatment: pocket.pocket ? pocket.pocket.pocket_treatment : "",
          price: pocket.pocket ? pocket.pocket.price : "",
          classs: pocket.patient_schedule ? pocket.patient_schedule.bunk.classs.name : "",
          corps: pocket.patient_schedule ? pocket.patient_schedule.bunk.corps.name : "",
          floor: pocket.patient_schedule ? pocket.patient_schedule.bunk.floor.name : "",
          room: pocket.patient_schedule ? pocket.patient_schedule.bunk.hospital_room.name : "",
          bunkname: pocket.patient_schedule ? pocket.patient_schedule.bunk.name : "",
          start: pocket.patient_schedule.start,
          end: pocket.patient_schedule.end,
          patient_schedule_id: pocket.patient_schedule_id
        };
        this.selected_pockets.push(old_pocket);
      });
    },
    async closeDrawerAddPartnerDoctor(obj) {
      if (obj.reload) {
        await this.partnerDoctorsList({ column: "name", order: "asc" }).then(
          (res) => {
            this.form.partner_doctor_id = this.selected_partner_doctor.id;
          }
        );
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePreview(file) {
      console.log(file);
    },
    pocketFields(pocket) {
      return [
        { label: this.$t("message.description"), value: pocket.description },
        { label: this.$t("message.price_one_day_without_room_price"), value: pocket.price || 0 },
        { label: this.$t("message.price_one_day_min_room_price"), value: this.lowPriceFilter(pocket.pocket_rooms, pocket.price) || '0' },
        { label: this.$t("message.class"), value: pocket.classs || '—' },
        { label: this.$t("message.corps"), value: pocket.corps || '—' },
        { label: this.$t("message.floor"), value: pocket.floor || '—' },
        { label: this.$t("message.room"), value: pocket.room || '—' },
        { label: this.$t("message.bunk"), value: pocket.bunkname || '—' },
        { label: this.$t("message.arrival_date"), value: pocket.start || '—' },
        { label: this.$t("message.departure_date"), value: pocket.end || '—' }
      ];
    },
    openSchedule(index) {
      this.schedule_pocket_index = index;
      store.dispatch("schedulesCabinets/setScheduleId", this.selected_pockets[index].patient_schedule_id);
      this.drawer.room.status = true;
    },
    pocketTitle(index) {
      return i18n.t(`message.pocket`) + ' ' + (index + 1);
    },
    closePage() {
      this.selected_pockets = [];
      this.close();
    }
  },
};
</script>
<style scoped>
.userPasten_info {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.title_pp {
  font-size: 14px;
  color: #9ca3af;
}
.name_h {
  font-size: 16px;
  color: #1f2937;
  font-weight: 500;
}
.el-card {
  border: 1px solid #e5e7eb;
}
</style>